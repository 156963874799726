.downloads{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.download-buttons{
    display: flex;
    justify-content: center;
    gap: 10px;
}