.app-footer {
    padding: 10px;
    background-color: var(--joy-palette-background-componentBg);
    display: flex;
    flex-direction: column;
    -moz-box-align: center;
    align-items: center;
    border-top-style: solid;
    border-top-width: 1px;
    border-color: var(--joy-palette-divider);
    text-align: center;
}

.app-footer div{
  padding: 3px 0;
}

@keyframes heartbeat {
    0% {
      transform: scale( .75);
    }
    20% {
      transform: scale( 1);
    }
    40% {
      transform: scale( .75);
    }
    60% {
      transform: scale( 1);
    }
    80% {
      transform: scale( .75);
    }
    100% {
      transform: scale( .75);
    }
  }

.heart {
    animation: heartbeat 1s infinite;
  }