.App-header {
    padding: 10px;
    background-color: var(--joy-palette-background-componentBg);
    display: flex;
    flex-direction: row;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    grid-column: 1 / -1;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--joy-palette-divider);
    position: sticky;
    top: 0px;
    z-index: 1100; 
}

.left{
    display: flex;
    gap: 10px;        
}